import _ from 'lodash';
import * as Yup from 'yup';

import {
  InvestmentExperience,
  InvestmentObjective,
  LiquidityNeeds,
  TimeHorizon,
} from '../../../constants/accountCompletion';
import Dropdown from '../../../lib/FormComponents/Dropdown';
import RadioGroup from '../../../lib/FormComponents/RadioGroup';
import FinancialInvestmentComp from '../../../lib/PortfolioComponents/FinancialInvestmentComp';
import { MenuNames } from '../../../models/menus.enum';

import * as Styles from './styles';
import { getDisplayValue, getFinancialInvestmentExperienceDisplayValue } from './utils';
import { suitabilityInformationValidation } from './validations';

const dropDownsValidation = Yup.object().shape({
  tradesPerYear: Yup.string().required('Required'),
  yearsOfExperience: Yup.string().required('Required'),
});

const liquidityNeedsDisclaimerText =
  'In order to qualify for My IPO Offers your Liquidity Needs must be "Somewhat Important" or "Not Important"';

export const suitabilityInformationSectionFields = (accountDetails: any) => [
  {
    label: 'Liquidity Needs',
    subheader: 'Liquidity Needs',
    hasModal: true,
    displayValue: (
      <div className={Styles.displayValue}>
        {
          _.find(LiquidityNeeds, {
            value:
              !_.isEmpty(accountDetails) && !_.isEmpty(accountDetails.liquidityNeeds)
                ? accountDetails.liquidityNeeds
                : null,
          })?.text
        }
      </div>
    ),
    warning:
      !_.isEmpty(accountDetails) &&
      accountDetails?.liquidityNeeds &&
      accountDetails?.liquidityNeeds === 'veryImportant' ? (
        <div className={'warning'} style={{ marginLeft: 0 }}>
          <i className='ri-error-warning-line' />
          {liquidityNeedsDisclaimerText}
        </div>
      ) : null,
    component: Dropdown,
    key: 'liquidityNeeds',
    readOnly: false,
    showInRow: true,
    props: {
      name: 'liquidityNeeds',
      placeholder: 'Select',
      options: LiquidityNeeds,
      value:
        !_.isEmpty(accountDetails) && !_.isEmpty(accountDetails.liquidityNeeds) ? accountDetails.liquidityNeeds : null,
    },
  },
  {
    label: 'Investment Objectives',
    displayValue: getDisplayValue(InvestmentObjective, accountDetails, 'investmentObjectives'),
    component: RadioGroup,
    key: 'investmentObjectives',
    readOnly: false,
    showInRow: false,
    props: {
      name: 'investmentObjectives',
      options: InvestmentObjective,
      value:
        !_.isEmpty(accountDetails) && !_.isEmpty(accountDetails.investmentObjectives)
          ? accountDetails.investmentObjectives
          : null,
    },
  },

  {
    label: 'Time Horizon',
    displayValue: getDisplayValue(TimeHorizon, accountDetails, 'timeHorizon'),
    component: RadioGroup,
    key: 'timeHorizon',
    readOnly: false,
    showInRow: false,
    props: {
      name: 'timeHorizon',
      options: TimeHorizon,
      value: !_.isEmpty(accountDetails) && !_.isEmpty(accountDetails.timeHorizon) ? accountDetails.timeHorizon : null,
    },
  },
  {
    label: 'Financial Investment Experience',
    tooltipText:
      'Asset types you are currently trading or have traded. This may include investments and transactions performed by you or on your behalf.',
    displayValue: getFinancialInvestmentExperienceDisplayValue(
      InvestmentExperience,
      accountDetails,
      'financialInvestmentExperience',
    ),
    customValidationSchema: Yup.object().shape({
      financialInvestmentExperience: Yup.object()
        .shape({
          margins: Yup.lazy(value => {
            if (value !== undefined) {
              return dropDownsValidation;
            }

            return Yup.mixed().notRequired().default(undefined);
          }),
          stocks: Yup.lazy(value => {
            if (value !== undefined) {
              return dropDownsValidation;
            }

            return Yup.mixed().notRequired().default(undefined);
          }),
          options: Yup.lazy(value => {
            if (value !== undefined) {
              return dropDownsValidation;
            }

            return Yup.mixed().notRequired().default(undefined);
          }),
          bonds: Yup.lazy(value => {
            if (value !== undefined) {
              return dropDownsValidation;
            }

            return Yup.mixed().notRequired().default(undefined);
          }),
          commodities: Yup.lazy(value => {
            if (value !== undefined) {
              return dropDownsValidation;
            }

            return Yup.mixed().notRequired().default(undefined);
          }),
        })
        .notRequired()
        .default(null)
        .nullable(),
    }),
    component: FinancialInvestmentComp,
    key: 'financialInvestmentExperience',
    readOnly: false,
    showInRow: false,
    passFormAsProp: true,
    props: {
      name: 'financialInvestmentExperience',
      value:
        !_.isEmpty(accountDetails) && !_.isEmpty(accountDetails.financialInvestmentExperience)
          ? accountDetails.financialInvestmentExperience
          : {},
    },
  },
];

export const getSuitabilityInformationSections = (suitabilityInformation: any) => {
  return {
    menuName: MenuNames.SUITABILITY_INFORMATION,
    path: 'suitability-information',
    sections: [
      {
        sectionLabel: MenuNames.SUITABILITY_INFORMATION,
        sectionKey: 'suitability_information',
        sectionFields: [...suitabilityInformationSectionFields(suitabilityInformation)],
        isCompleted: suitabilityInformationValidation?.isValidSync(suitabilityInformation),
        validationSchema: suitabilityInformationValidation,
      },
    ],
  };
};

export const modalContent = (
  <div>
    <p className={Styles.liquidityValueDescription}>
      Liquidity is the ability to quickly and easily convert to cash all, or a portion, of the investments in this
      account without experiencing significant loss in value from, for example, the lack of a ready market, or incurring
      significant costs or penalties.
    </p>
    <div className={Styles.liquidityValueTitle}>Very Important</div>
    <p className={Styles.liquidityValueDescription}>
      The ability to quickly and easily convert to cash all or a portion of the investments in under 5 years in this
      account is very important.
    </p>
    <div className={Styles.liquidityValueTitle}>Somewhat Important</div>
    <p className={Styles.liquidityValueDescription}>
      The ability to quickly and easily convert to cash all or a portion of the investments in this account in over 5
      years is somewhat important.
    </p>
    <div className={Styles.liquidityValueTitle}>Not Important</div>
    <p className={Styles.liquidityValueDescription}>
      The ability to quickly and easily convert to cash all or a portion of the investments in this account is not
      important.
    </p>
  </div>
);

export const getRiskToleranceMessage = (investmentObjective: string) => {
  switch (investmentObjective) {
    case 'capitalPreservation':
      return `'Capital Preservation' has a 'Low' Risk Tolerance`;
    case 'income':
      return `'Income' has a 'Low' Risk Tolerance`;
    case 'growth':
      return `'Growth' has a 'High' Risk Tolerance`;
    case 'growthAndIncome':
      return `'Growth And Income' has a 'Medium' Risk Tolerance`;
    case 'speculation':
      return `'Speculation' has a 'High' Risk Tolerance`;
    default:
      return null;
  }
};

export const getRiskToleranceValue = (investmentObjective: string) => {
  switch (investmentObjective) {
    case 'capitalPreservation':
      return 'low';
    case 'income':
      return 'low';
    case 'growth':
      return 'high';
    case 'growthAndIncome':
      return 'medium';
    case 'speculation':
      return 'high';
    default:
      return null;
  }
};

export const generateFormTip = (fieldKey: any, value: any) => {
  if (fieldKey === 'liquidityNeeds' && value === 'veryImportant') {
    return (
      <div className={'warning'} style={{ marginLeft: 0 }}>
        <i className='ri-error-warning-line' />
        {liquidityNeedsDisclaimerText}
      </div>
    );
  }
};
