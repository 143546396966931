import React, { Fragment, useEffect, useState } from 'react';

import { Col, Row, Tooltip } from 'antd';
import { Formik } from 'formik';
import _, { get } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import {
  clearPatchSuitabilityInformation,
  clearUpdateSuitabilityInformation,
  getSuitabilityInformation,
  patchSuitabilityInformation,
  updateSuitabilityInformation,
} from '../../../actions';
import FieldWrapper from '../../../lib/FormComponents/FieldWrapper';
import { MButton } from '../../../lib/FormComponents/MButton/MButton';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { MDivider } from '../../../lib/MDivider/MDivider';
import Spinner from '../../../lib/Miscellaneous/Spinner';
import { MModal } from '../../../lib/MModal/MModal';
import { Color, ScreenBreakpoint } from '../../../styles';

import { generateFormTip, getSuitabilityInformationSections, modalContent } from './fields';
import * as Styles from './styles';

const SuitabilityInformationUnmapped = ({
  suitabilityInformation,
  getSuitabilityInformation,
  updateSuitabilityInformation,
  patchSuitabilityInformation,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadingSuitabilityInformation = useSelector((state: any) =>
    Boolean(state.accountDetails?.suitabilityInformation.__requested),
  );
  const succeededUpdateSuitabilityInformation = useSelector(
    (state: any) => state?.accountDetails?.updateSuitabilityInformation?.__succeeded,
  );

  const succeededPatchSuitabilityInformation = useSelector(
    (state: any) => state?.accountDetails?.patchSuitabilityInformation?.__succeeded,
  );

  const isUpdateSuitabilityInformationLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.updateSuitabilityInformation?.__requested),
  );

  const isPatchSuitabilityInformationLoading = useSelector((state: any) =>
    Boolean(state?.accountDetails?.patchSuitabilityInformation?.__requested),
  );

  const accountId = useSelector((state: any) => state.accountDetails.accountHolder?.data?.accountId);
  const accountUuid = useSelector((state: any) => state.accountDetails.accountHolder?.data?.id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sectionUpdated, setSectionUpdated] = useState('');
  const [openedField, setOpenedField] = useState('');
  const [isFieldEditing, setIsFieldEditing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const menu: any = getSuitabilityInformationSections(suitabilityInformation);

  const isUpsertLoading = isUpdateSuitabilityInformationLoading || isPatchSuitabilityInformationLoading;
  const isSuitabilityInformationCompleted = () => !_.isEmpty(suitabilityInformation);

  const anyUpsertRequestSucceded = () => succeededPatchSuitabilityInformation || succeededUpdateSuitabilityInformation;

  const initialValues = (fields: any) =>
    Object.keys(fields)
      .map(key => ({
        key: fields[key].props.name,
        value: fields[key].props.value,
      }))
      .reduce(
        (obj, keyValuePair) => ({
          ...obj,
          [keyValuePair.key]: keyValuePair.value,
        }),
        {},
      );

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    await updateSuitabilityInformation(values, sectionUpdated);
  };

  useEffect(() => {
    if (isSubmitting && !isUpsertLoading) {
      setIsSubmitting(false);
    }
  }, [isPatchSuitabilityInformationLoading, isUpdateSuitabilityInformationLoading]);

  useEffect(() => {
    if (!accountId && isSuitabilityInformationCompleted() && succeededUpdateSuitabilityInformation) {
      window.gtag('event', 'account_suitability_information_complete');
    }

    if (!accountId && isSuitabilityInformationCompleted() && anyUpsertRequestSucceded()) {
      navigate(`/accounts/${accountUuid}/financial-information`);
    }
  }, [succeededPatchSuitabilityInformation, succeededUpdateSuitabilityInformation, suitabilityInformation]);

  useEffect(() => {
    if (_.isEmpty(suitabilityInformation) && !loadingSuitabilityInformation) {
      getSuitabilityInformation();
    }

    return () => {
      dispatch(clearPatchSuitabilityInformation());
      dispatch(clearUpdateSuitabilityInformation());
    };
  }, []);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.SuitabilityInformation}
      title={isMobile ? undefined : 'Suitability Information'}>
      {loadingSuitabilityInformation ? (
        <Spinner />
      ) : (
        menu.sections.map((section: any) => (
          <Row key={section.sectionKey}>
            <Col span={18} xs={24} sm={24}>
              <div className={Styles.formCard}>
                <>
                  <MModal
                    customWidth={520}
                    visible={isModalVisible}
                    title='Liquidity Needs'
                    tertiaryButtonText='Close'
                    onTertiaryButtonClick={() => {
                      setIsModalVisible(false);
                    }}
                    onClose={() => {
                      setIsModalVisible(false);
                    }}>
                    {modalContent}
                  </MModal>
                  <Formik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={initialValues(section.sectionFields)}
                    onSubmit={values => {
                      onSubmit(values);
                    }}
                    validationSchema={section.validationSchema}>
                    {form => {
                      const formValues: any = form.values;

                      return (
                        <>
                          {section.sectionFields.map((field: any) => {
                            const JSXRenderableComp = field.component;

                            return (
                              <Fragment key={`${field.key}_frag`}>
                                <Row key={`${field.key}_row`}>
                                  {field.subheader && (
                                    <Col span={24} className={Styles.sectionHeader} style={{ marginTop: 16 }}>
                                      {field.subheader}
                                    </Col>
                                  )}

                                  {field.showInRow && (
                                    <Col span={12}>
                                      <div
                                        className={Styles.fieldLabel}
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 15,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}>
                                        {field.label}
                                        {field?.hasModal && (
                                          <i
                                            style={{ ...Styles.Tooltip }}
                                            className={`ri-information-line`}
                                            onClick={() => setIsModalVisible(true)}
                                          />
                                        )}
                                      </div>
                                    </Col>
                                  )}
                                  <Col span={field.showInRow ? 12 : 24}>
                                    {field.key !== 'liquidityNeeds' && (
                                      <div className={Styles.sectionHeader} style={{ marginTop: 16 }}>
                                        {field.label}
                                        {field?.tooltipText && (
                                          <Tooltip
                                            getTooltipContainer={(triggerNode: any) => triggerNode.parentNode}
                                            placement='top'
                                            title={field?.tooltipText}
                                            color={Color.GRAYSCALE.GRAY1}>
                                            <i style={{ ...Styles.Tooltip }} className={`ri-information-line`} />
                                          </Tooltip>
                                        )}
                                      </div>
                                    )}

                                    <FieldWrapper
                                      key={field.key}
                                      displayValue={field.displayValue}
                                      name={field.key}
                                      form={form}
                                      value={field.props.value}
                                      openedField={openedField}
                                      initialMode={section.isCompleted || field.readOnly ? 'view' : 'edit'}
                                      isSectionCompleted={section.isCompleted}
                                      readOnly={field.readOnly}
                                      isFieldEditing={isFieldEditing}
                                      customValidationSchema={field?.customValidationSchema}
                                      onFieldEdit={(name: string) => {
                                        setOpenedField(name);
                                        setIsFieldEditing(true);
                                      }}
                                      onFieldSubmit={async (values: any) => {
                                        setIsSubmitting(true);
                                        setIsFieldEditing(false);
                                        setOpenedField('');
                                        setSectionUpdated(section.sectionKey);

                                        await patchSuitabilityInformation(values, section.sectionKey);
                                      }}
                                      onFieldClose={() => {
                                        setOpenedField('');
                                        form.resetForm();
                                      }}>
                                      <JSXRenderableComp
                                        key={`${field.key}_field_`}
                                        {...field.props}
                                        {...form}
                                        form={field.passFormAsProp ? form : undefined}
                                        name={field.key}
                                        value={formValues[field.key]}
                                      />
                                    </FieldWrapper>
                                  </Col>
                                  {!section?.isCompleted && generateFormTip(field.key, formValues[field.key])}
                                  {section?.isCompleted && field?.tip}
                                </Row>
                              </Fragment>
                            );
                          })}
                          <MDivider />
                          {!section.isCompleted && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <MButton
                                loading={isUpsertLoading}
                                testId={`account-btn-save-${section.sectionKey}`}
                                type='secondary'
                                disabled={loadingSuitabilityInformation || isUpsertLoading}
                                onClick={() => {
                                  form.submitForm();
                                  setSectionUpdated(section.sectionKey);
                                }}>
                                Save
                              </MButton>
                            </div>
                          )}
                        </>
                      );
                    }}
                  </Formik>
                </>
              </div>
            </Col>

            <Col span={3} />
          </Row>
        ))
      )}
    </MyAccountLayout>
  );
};

const mapStateToProps = (state: any) => ({
  suitabilityInformation: get(state, 'accountDetails.suitabilityInformation.data'),
});

const mapDispatchToProps = {
  getSuitabilityInformation,
  updateSuitabilityInformation,
  patchSuitabilityInformation,
};

export const SuitabilityInformation = connect(mapStateToProps, mapDispatchToProps)(SuitabilityInformationUnmapped);
